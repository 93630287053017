/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Hussein Awala",
  title: "Hi all, I'm Hussein",
  subTitle: emoji(
      "A senior Data Engineer at Voodoo and Apache committer & PMC member on Airflow project, having an extensive experience of building Data pipelines and ETL using Apache Spark, Apache Airflow, Apache Iceberg & Apache Hudi, and a big experience with Python, Docker, Kubernetes, MongoDB, SQL and cloud platforms likes AWS, GCP and OVH."
  ),
  resumeLink:
      "https://drive.google.com/file/d/16V5ePIEc6BxlaQLGEg2WrXthyD8AW7Qo/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/hussein-awala",
  linkedin: "https://www.linkedin.com/in/husseinawala/",
  twitter: "https://x.com/_HusseinAwala",
  gmail: "hussein@awala.fr",
  medium: "https://medium.com/@hussein-awala",
  stackoverflow: "https://stackoverflow.com/users/9560594/hussein-awala",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "A TALENTED DATA ENGINEER WHO WANTS TO SOLVE REAL BIG DATA CHALLENGES",
  skills: [
    emoji(
        "⚡ I design, build, scale, and evolve large scale systems/apps"
    ),
    emoji("⚡ I design and develop modern & GDPR compliant lakehouse architectures using Apache Hudi, Iceberg and Spark"),
    emoji("⚡ I analyse huge data sets using Big Data frameworks and ML"),
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "JAVA",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "Airflow",
      fontAwesomeClassname: "fas fa-clock"
    },
    {
      skillName: "Spark",
      fontAwesomeClassname: "fas fa-cog"
    },
    {
      skillName: "Iceberg",
      fontAwesomeClassname: "fas fa-cog"
    },
    {
      skillName: "Hudi",
      fontAwesomeClassname: "fas fa-cog"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Kubernetes",
      fontAwesomeClassname: "fab fa-docker"
    },
    {
      skillName: "Kafka",
      fontAwesomeClassname: "fas fa-stream"
    },
    {
      skillName: "Argo Workflows",
      fontAwesomeClassname: "fas fa-clock"
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "GCP",
      fontAwesomeClassname: "fab fa-google"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Grenoble INP ENSIMAG",
      logo: require("./assets/images/ensimag.png"),
      subHeader: "MSc. Data Science",
      duration: "September 2018 - September 2019",
      desc: "Ranked top 10% in the program. Took courses about Ml, optimization, Data management, Distributed Systems, ...",
      descBullets: []
    },
    {
      schoolName: "Lebanese University Faculty Of Science",
      logo: require("./assets/images/lu.jpg"),
      subHeader: "MSc. AI & Big Data",
      duration: "September 2017 - Jul 2018",
      desc:
          "Ranked 1st in the program. Took courses about Software Engineering, Big Data, Operating Systems, AI & ML, Security, ...",
      descBullets: []
    },
    {
      schoolName: "Lebanese University Faculty Of Science",
      logo: require("./assets/images/lu.jpg"),
      subHeader: "BSc. Computer Science",
      duration: "September 2014 - Jul 2017",
      desc:
          "Ranked 2nd in the program. Took courses about Software Engineering, Operating Systems, Web and Mobile development, Networking, Math, ...",
      descBullets: []
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Python",
      progressPercentage: "100%"
    },
    {
      Stack: "JAVA",
      progressPercentage: "100%"
    },
    {
      Stack: "Airflow",
      progressPercentage: "100%"
    },
    {
      Stack: "Spark",
      progressPercentage: "100%"
    },
    {
      Stack: "Iceberg",
      progressPercentage: "100%"
    },
    {
      Stack: "Hudi",
      progressPercentage: "100%"
    },
    {
      Stack: "Docker",
      progressPercentage: "100%"
    },
    {
      Stack: "Kubernetes",
      progressPercentage: "100%"
    },
    {
      Stack: "helm",
      progressPercentage: "100%"
    },
    {
      Stack: "Kafka",
      progressPercentage: "90%"
    },
    {
      Stack: "SQL",
      progressPercentage: "90%"
    },
    {
      Stack: "MongoDB",
      progressPercentage: "90%"
    },
    {
      Stack: "Redis",
      progressPercentage: "90%"
    },
    {
      Stack: "gRPC",
      progressPercentage: "90%"
    },
    {
      Stack: "terraform",
      progressPercentage: "90%"
    },
    {
      Stack: "Argo Workflows",
      progressPercentage: "80%"
    },
    {
      Stack: "GCP",
      progressPercentage: "80%"
    },
    {
      Stack: "Github actions",
      progressPercentage: "80%"
    },
    {
      Stack: "Concourse",
      progressPercentage: "80%"
    },
    {
      Stack: "Travis",
      progressPercentage: "80%"
    },
    {
      Stack: "CircleCI",
      progressPercentage: "80%"
    },
    {
      Stack: "OVH",
      progressPercentage: "70%"
    },
    {
      Stack: "Elastic Search",
      progressPercentage: "70%"
    },
  ],
  displayCodersrank: true // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Sr. Data Engineer",
      company: "Voodoo",
      companylogo: require("./assets/images/Voodoo.png"),
      date: "Jan 2024 - Present",
      desc:
          "Sr. Data Engineer within ADN (Ads Network) data core team",
      descBullets: [
        "Maintain and evolve the bidding system, which is a complex real-time system composed of many microservices communicating via gRPC, ML models connected to a feature store, some SQL and NoSQL databases, and a lot of other components",
        "Design and develop a GDPR compliant data lakehouse architecture using Apache Iceberg",
        "Develop and improve ETL pipelines using Apache Spark, DBT and Airflow: some of my daily routines tasks are to optimize the queries, improve their monitoring, automate the data processing, and improve their infrastructure to make them more scalable, fault tolerant and cost effective",
        "Improve the ML workflow and the feature store feeding and serving",
      ]
    },
    {
      role: "Sr. Data Engineer",
      company: "Leboncoin",
      companylogo: require("./assets/images/leboncoin.png"),
      date: "Oct 2021 – Jan 2024",
      desc:
          "Sr. Data Engineer in the data platform team",
      descBullets: [
        "Develop complex applications ensuring optimal data circulation, and ensure their reliability: data exposure API, streaming applications, industrialization of machine learning models",
        "Support internal teams in the use of the platform",
        "Optimize the architecture and the cloud environment of the company: storage, security, automation",
        "Guarantee the proper functioning, availability, evolution and performance of the tools",
        "Ensure the security of user data on the data platform, in compliance with the regulations in force (GDPR, e-privacy)",
      ]
    },
    {
      role: "Data Engineer",
      company: "Data4Risk",
      companylogo: require("./assets/images/D4R.jpeg"),
      date: "Jan 2020 – Oct 2022",
      desc:
          "Head of Data, working on Data4Risk B2B projects and some external consulting projects.",
      descBullets: [
        "Designing and building stream processing applications and data processing pipelines & ETL using Docker, Pyspark, Kafka, Argo Workflows, mongoDB, MySQL, MinIO and Kubernetes on GCP or OVH cloud",
        "Designing and training machine learning models and pipelines using Keras, Tensorlow, MLlib and other libraries to classify and process satellite images and deploying these models using MLflow",
        "As a consultant, I offer advice and expertise to other companies on Big Data and cloud projects and I help them to find solutions to their challenges (scalable information systems, data lakes, scalable web apps...)",
        "As a head of data, I assist my colleagues, and I participate in making most company decisions, which contributes to the growth of the company"
      ]
    },
    {
      role: "Data Engineer",
      company: "NetDevices",
      companylogo: require("./assets/images/netdevices.jpg"),
      date: "Sep 2019 – Dec 2019",
      desc:
          "Junior Data Engineer, worked on Data4Risk weather alerts project",
      descBullets: [
        "Developed containerised, scalable and fault tolerant programs to process meteorological data",
        "Built a development environment on a docker swarm cluster, and connected it to jenkins CI/CD and gitlab to automate building, testing and deploying the docker images",
        "Proposed and tested a new system architecture based on Kafka, Zookeeper and PySpark streaming to ultimate the system scalabilty on a Kubernetes cluster"
      ]
    },
    {
      role: "Trainee Data Engineer",
      company: "NetDevices",
      companylogo: require("./assets/images/netdevices.jpg"),
      date: "Feb 2019 – Oct 2019",
      desc:
          "Trainee Data Engineer, worked on Data4Risk weather alerts and flood forecasting projects",
      descBullets: [
        "Designed and  developed an information system which downloads and analysis Meteo France meteorological data, to generate bulletins containing 6 types of forecasted alerts in the French postal areas. This system is managed by Xen and Docker Swarm, it uses many different technologies and languages such as Python, Node. JS, MySQL, MongoDB, Kafka, and other… ",
        "Worked on a time series prediction model, to forecast the floods on the french rivers, the data used in this project are EFAS, ERA5, ARPEGE and “Eau de France”",
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "hussein-awala", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/airflow.png"),
      projectName: "Airflow",
      projectDesc: "Actively contributing to Apache Airflow project by answering questions on stack overflow and discussions on github, reviewing open PR, adding new feature and fixing bugs, and testing pre-release versions",
      footerLink: [
        {
          name: "Apache Airflow",
          url: "https://airflow.apache.org/"
        }
      ]
    },
    {
      image: require("./assets/images/spark-on-kubernetes.png"),
      projectName: "spark-on-k8s",
      projectDesc: "I'm developing a new Python package used to easily create and manage Apache Spark applications on Kubernetes clusters. This package provides a Python client, a CLI, and an Apache Airflow operator to create the Spark applications, and a REST API & a web interface to monitor them and access their Spark UIs using a reverse proxy.",
      footerLink: [
        {
          name: "Spark On K8S",
          url: "hussein.awala.fr/spark-on-k8s"
        }
      ]
    },
    {
      image: require("./assets/images/rgpd.png"),
      projectName: "Leboncoin Privacy Lakehouse",
      projectDesc: "Migrating Leboncoin old Data Lake to a new design based on apache Hudi, Kafka, Airflow and Spark to support ACID transactions, update and delete operations, auto-compaction, data indexing, and a lot of new feature. The main goal of this project is applying the GDPR compliance on LBC datalake by anonymizing and pseudonymizing users data.",
      footerLink: [
        {
          name: "Apache Hudi",
          url: "https://hudi.apache.org/",
        }
      ]
    },
    {
      image: require("./assets/images/fraud.jpg"),
      projectName: "Fraud detection",
      projectDesc: "Building low latency kafka stream application to process users actions via ML models, to detect the fraud actions and block them. In this project, I used Spring Cloud Stream (JAVA) to develop the stream application, K8S and Helm to deploy the application and manage the scalability, FastAPI to serve the ML models, Datadog as metric store and internal project based on KStream & DynamoDB as a feature store",
      footerLink: [
        {
          name: "Spring Cloud Stream",
          url: "https://github.com/spring-cloud/spring-cloud-stream",
        }
      ]
    },
    {
      image: require("./assets/images/pairing.png"),
      projectName: "Pairing",
      projectDesc: "Design, implement and monitor a big data stack deployed on K8S cluster on OVH cloud, consists of MinIO proxy connected to OVH object storage as a data lake to store the unstructured data, Kafka as a message broker to deliver the data from the scraping service to data lake, PySpark structured streaming as a data ingestor to extract and load the data, PySpark SQL as a transformer and processor to transform the data and process it via ML and expert models, FastAPI to deploy the processing models as web services, MongoDB as a database for structured and semistructured data, DreamFactory as a REST API connector for MongoDB and datadog as a monitoring and logs collecting tools.",
      footerLink: [
        {
          name: "Project Website",
          url: "https://www.pairingtechnology.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/D4R.jpeg"),
      projectName: "Data4Risk",
      projectDesc: "Design, implement and monitor an scalable and fault tolerant information system which download and process meteorological data files to detect complex events like thunderstorms,  hail and fast flood, and generate alerts per postal code. This information system is deployed on a Google Kubernetes Engine cluster by terraform and helm, it consists of bash scripts to download the data files, python and nodejs scripts to process the data files, MongoDB as a database, Argo Workflows as a scheduler and ETL tool, MinIO as a temporary artifactory, Google Cloud Storage as a data lake, ELK stack as log manager and Prometheus as monitoring tool.",
      footerLink: [
        {
          name: "Company Website",
          url: "https://www.data4risk.com/"
        }
      ]
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
      "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle: "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+33761654896",
  email_address: "hussein@awala.fr",
  display: true
};

// Twitter Section

const twitterDetails = {
  userName: "_HusseinAwala", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
